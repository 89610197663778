import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'
import BigButton from '../../components/Ui/BigButton'

const Zonedeservices = () => {
  const metaTitle = 'Zone de services | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Zone de services"
      />
      <div className="container content pb-12">
        <div>

        {/* TOP IMAGE */}
        <div className="max-w-[2048px] max-h-[767px] pb-6">
          <StaticImage src="../../images/zonedeservices.png" alt="Image représentant la zone de service" imgClassName="rounded-3xl"/>
        </div>

        {/* HIGHLIGHTED TEXT */}
        <div>
          <h5>Située près du Parvis, cette zone permettra aux festivalier·ères de trouver plusieurs commodités.</h5>
        </div>

        {/* Le Disquaire présenté */}
        <div>
          <h2>Le Disquaire & la boutique</h2>
          <p>Viens te procurer un vinyle de ton artiste préféré.e au disquaire! Dans la boutique, tu pourras aussi te procurer des articles à l’effigie du Festif!, comme nos pièces de vêtements revalorisés, nos gourdes réutilisables, notre sérigraphie, la sauce piquante de La Pimenterie, le mélange de Café Charlevoix et les boissons énergisantes naturelles OOYA.</p>
          <br/>
          <div className='ul-container'>
            <ul className='ml-9'>
              <li>Mercredi 16 juillet: 16h à 21h</li>
              <li>Jeudi 17 juillet : 12h à 21h</li>
              <li>Vendredi 18 juillet : 10h à 21h</li>
              <li>Samedi 19 juillet : 10h à 21h</li>
              <li>Dimanche 20 juillet : 10h à 15h</li>
            </ul>
          </div>
        </div>

        {/* KIOSQUE D'INFORMATION */}
        <div>
          <h2>Kiosque d'information</h2>
          <p>Tu as une question pendant le festival? Viens nous voir au kiosque d’info du festival, situé près du Parvis du Festif! dans le parc localisé à côté du Carrefour culturel Paul Médéric du 16 au 20 juillet 2025.</p>
          <br/>
          <div className='ul-container'>
            <ul className='ml-9'>
              <li>Mercredi 16 juillet : 16h à 21h</li>
              <li>Jeudi 17 juillet : 12h à 21h</li>
              <li>Vendredi 18 juillet : 10h à 21h</li>
              <li>Samedi 19 juillet : 10h à 21h</li>
              <li>Dimanche 20 juillet : 10h à 15h</li>
            </ul>
          </div>
        </div>

        <div>
          <h2>Borne de recharge Bell</h2>
          <p>Besoin de charger ton téléphone pour ne manquer aucune notification ? Aucun problème, Bell t'installe une borne de recharge au Parvis!</p>
        </div>

        <div>
          <h2>Zone de réconfort</h2>
          <p><BigButton href="/infos/zonedereconfort#info_content" text="Zone de réconfort" target='_self'/></p>
        </div>

        </div>
      </div>
    </Wolflayout>
  )
}

export default Zonedeservices
